import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { Button } from 'antd';

const DownloadContainer = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
//   margin: 0 12px;

  & code {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: .2em .4em;
    font-size: 85%;
    text-align: center;
  }

  & p {
    text-align: left;
  }

  .center-text {
    text-align: center;
  }

  .download {
  }

  .download-button {
    font-weight: 500;
   }

    .download-button-container {
        margin: 36px 12px;
    }

    .center-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
`

function DownloadPage() {
  return (
    <Layout hasSider={false} title="Download">
      <DownloadContainer className='download'>
        <div className='download'>
            <p>
            If Amesp is utilized in your work, the following should be cited in your article:
            </p>
            <p>
              <p class="center-text"><code>
              Yingfeng Zhang 2024 Electron. Struct. 6 037002. 
              </code></p>
            </p>
            <p class="center-text"><code>
            DOI: 10.1088/2516-1075/ad5cb5
            </code>
            </p>
        </div>
        <div className='download-button-container'>
            <Button size='large' className='download-button' type="primary" href="/Amesp_2.1_dev.zip">
                Download
            </Button>
        </div>
        <div className='center-column'>
            <p>
            Current version: <code>2.1(dev)</code>
            </p>
            <p>
            Last update: 2025-01-13
            </p>
            <p>
            New features: TDA-aTB analytic gradient
            </p>
        </div>
      </DownloadContainer>
    </Layout>
  )
}

export default DownloadPage;
